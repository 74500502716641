<template>
  <div class="content-wrapper">
    <div class="row content-header">
      <div class="content-header-left mb-2 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12 d-flex align-items-center justify-content-between">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Create Cluster Plan
            </h2>
            <router-link
                class="btn btn-primary"
                :to="{ name: 'apps-cluster-plan-list'}"
            >
              List Cluster Plan
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <div class="row match-height">
        <div class="col-12">
          <div
              data-v-5cff0742=""
              class="card"
          ><!----><!---->
            <div
                data-v-5cff0742=""
                class="card-header"
            />
            <div
                data-v-5cff0742=""
                class="card-body"
            ><!----><!---->
              <form
                  data-v-5cff0742=""
                  class="col-12 row"
              >
                <div
                    data-v-5cff0742=""
                    class="row col-6"
                >
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        id="__BVID__824"
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    >
                      <div class="col">
                        <label
                            id="__BVID__824__BV_label_"
                            for="h-first-name"
                            class="col-md-4 col-form-label"
                        >
                          Name</label>
                        <input
                            id="h-first-name"
                            v-model="name"
                            required
                            type="text"
                            placeholder="Name"
                            class="form-control col-11"
                        ><!----><!----><!----></div>
                    </div>
                  </div>
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        id="__BVI__824"
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    >
                      <div class="col">
                        <label
                            id=""
                            for="h-first-name"
                            class="col-md-4 col-form-label"
                        >
                          Limit</label>
                        <input
                            id="h--name"
                            v-model="limit"
                            required
                            type="number"
                            min="1"
                            placeholder="Limit"
                            class="form-control col-11"
                        ><!----><!----><!----></div>
                    </div>
                  </div>



                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        id="__BVID__826"
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    >
                      <div class="col form-group">
                        <label
                            id="__BVID__826__BV_label_"
                            class="col-md-4 col-form-label"
                        >Region</label>
                        <select
                            id=""
                            v-model="region"
                            class="form-control col-11"
                            name=""
                            required
                        >
                          <option
                              v-for="(item,index) in getterListWPRegion"
                              :value="item.id"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        id=""
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    >
                      <div class="col form-group">
                        <label
                            id=""
                            class="col-md-4 col-form-label"
                        >Plan</label>
                        <select
                            id=""
                            v-model="plan_id"
                            class="form-control col-11"
                            name=""
                            required
                        >
                          <option
                              v-for="(item) in listPlan"
                              :value="item.id"
                          >
                            Băng thông : {{ item.speed }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                    data-v-5cff0742=""
                    class="row col-6"
                >
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        role="group"
                        class="form-row form-group"
                    >
                      <div class="col">
                        <label
                            for="h-first-name"
                            class="col-md-4 col-form-label"
                        >
                          File</label>
                        <div
                            class="input-file"
                            style="cursor: pointer"
                        >
                          <label class="label-input-file">
                            <b>Choose File</b>
                            <input
                                id="file"
                                type="file"
                                @change="onFileChange( $event)"
                            >
                          </label>
                        </div>

                        <div id="preview">
                          <img
                              v-if="url"
                              :src="url"
                          >
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div
                    data-v-5cff0742=""
                    class="offset-md-4 col"
                >
                  <button
                      data-v-5cff0742=""
                      type="button"
                      class="btn m-5 btn-primary"
                      @click.prevent="submitAddClusterPlan()"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div><!----><!----></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex/dist/vuex.esm.browser'
import { mapGetters } from 'vuex'
import $ from 'jquery'
export default {

  name: 'ClusterPlanAdd',
  data() {
    return {
      name: '',
      disk: '',
      containers: '',
      price: 0,
      description: '',
      file: '',
      file1: '',
      file2: '',
      file3: '',
      file4: '',
      image: '',
      category: '',
      url: null,
      region: '',
      limit: 1,
      server: '',
      size: [],
      numSize: 1,
      listRegion:[],
      listPlan:[],
      plan_id:'',
    }
  },
  created() {
    this.getListWPRegion({})
    this.getListPlan({}).then(r=>{
      console.log('getListPlan', r)
      this.listPlan = r.data
    }).catch(e=>{
      console.log(e)
    })
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters(['getterListWPRegion','getterListServer','getterListSize']),
  },
  methods: {
    ...mapActions(['getListWPRegion','getListPlan','getListSize', 'createClusterPlanAPI']),
    // eslint-disable-next-line consistent-return
    submitAddClusterPlan() {
      const data = {
        name: this.name,
        file: this.file,
        region_id : this.region,
        limit : this.limit,
        plan_id : this.plan_id,
      }
      if (this.file === '') {
        // eslint-disable-next-line no-alert
        alert('choose file to upload!')
        return false
      }
      if (this.region === '') {
        // eslint-disable-next-line no-alert
        alert('choose region !')
        return false
      }
      if (this.plan_id === '') {
        // eslint-disable-next-line no-alert
        alert('choose plan!')
        return false
      }

      console.log('submitAddClusterPlan', data)
      this.createClusterPlanAPI(data)
    },
    onFileChange(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    onFileChangeImg1(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file1 = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    onFileChangeImg2(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file2 = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    onFileChangeImg3(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file3 = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    onFileChangeImg4(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file4 = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    submitAddWPSize(){
      this.numSize ++;
    }
  },
}
</script>

<style scoped>
#preview {
  /*align-items: center;*/
}

#preview img {
  max-width: 200px;
  max-height: 200px;
  object-fit: cover;
}

.input-file {
  border: 1px dashed #ccc;
  display: block;
  width: 100%;
  height: 150px;
}
.input-file-list {
  border: 1px dashed #ccc;
  display: block;
  width: 100%;
  height: 150px;
}

.label-input-file {
  position: relative;
  width: 100%;
}

.label-input-file b {
  display: block;
  width: 100%;
  font-weight: normal;
  text-align: center;
  padding-top: 73px;
  cursor: pointer;
}

.label-input-file input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

</style>
